import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// Components
import Layout from 'components/layout';

// Styles
import * as styles from './superior-technology.module.scss';

const SuperiorTechnologyPage = () => {
  const description = 'Reverso is a Fractional RF device that incorporates several innovative technological solutions, all intended to guarantee optimal clinical results skin resurfacing, tissue remodeling, stretch mark treatment and anti-aging treatment.';

  return (
    <Layout title="Reverso by INDIBA | Deep Fractional Radiofrequency for Skin Aging Treatment" meta={{ description, image: 'https://intelis-public.s3.us-east-2.amazonaws.com/image-device.png' }}>
      <div className={styles.Wrapper}>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h2>Superior Technology</h2>
            <h3>Speed</h3>
            <p className={styles.Half}>
              The Reverso tip emits radio-frequency pulses every second,
              and covers a work area of ~2 cm² per pulse.
              {' '}
              <br />
              The combination of
              <strong> a large tip area and short pulse intervals </strong>
              offers enhanced efficacy, and therefore a better treatment
              experience for users and patients alike:
            </p>
            <ul>
              <li>
                <p>
                  <strong>Completing treatment in minimal time </strong>
                  (only ~160 pulses required for a full-face treatment).
                </p>
              </li>
              <li>
                <p>
                  <strong>Pain sensation is significantly reduced.</strong>
                </p>
              </li>
            </ul>
          </div>
          <StaticImage src="../../images/speed-tech.png" alt="" className={styles.ImageSpeedTech} />
        </div>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h3>Power</h3>
            <p>
              Reverso generates
              <strong> energy of up to 10 W per pin. </strong>
              This high power causes substantial (yet carefully controlled)
              thermal destruction in patients’ skin.
            </p>
            <p>
              The quicker and the more powerful the thermal destruction is,
              the more effective and the less risky the resulting ablation, leading to a
              <strong> healthy and rapid tissue remodeling process.</strong>
            </p>
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h3>Depth of Penetration</h3>
            <div className={styles.Flex}>
              <p>
                Reverso implements the
                <strong> Very Sharp Pin (VSP) technology:</strong>
              </p>
              <StaticImage src="../../images/group-567-3.png" alt="" className={styles.ImageSkin} />
            </div>
            <p>
              The needles on the Reverso tips have a very sharp edge, at an angle of 15°,
              facilitating penetration to the dermis, in varying depths,
              <strong> up to a depth of 1 mm.</strong>
            </p>
          </div>
        </div>
        <div className={styles.Block}>
          <div className={styles.Text}>
            <h3>Uniform Energy Distribution</h3>
            <p>
              Each pin on the Reverso tip is individually controlled,
              and the energy delivery to the skin is optimized in a scanning mode,
              so that the
              <strong> RF energy is evenly distributed across the imprint</strong>
              , regardless of skin variation.
            </p>
            <p>
              The uniform energy distribution significantly
              reduces the risk of post-inflammatory hyper-
              <br />
              pigmentation. Moreover, because the Reverso pins are activated sequentially,
              patients’ pain sensation is significantly decreased.

            </p>
          </div>
        </div>
      </div>
      <div className={styles.ImageBrami}>
        <StaticImage src="../../images/brami-05706.jpeg" alt="Brami Device" />
      </div>
    </Layout>
  );
};

export default SuperiorTechnologyPage;
